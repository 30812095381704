import { Component, For, Show, mergeProps } from "solid-js";
import { A, createAsync } from "@solidjs/router";
import { type OpenPositionsFields } from "./OpenPositions.block";
import { getBuilderCareerEntries } from "~/services/builder";
import { contentWidth } from "~/utils/builder/enums";
import { link } from "solid-heroicons/solid";
import { Icon } from "solid-heroicons";
import Button from "./Button";

export type JobModel = {
  name: string;
  data: {
    publishAt: string;
    slug: string;
    department: string;
    reportingTo: string;
    location: string;
    content: string;
    applyNowUrl: string;
  };
};

const OpenPositions: Component<OpenPositionsFields> = (inProps) => {
  const content = createAsync(() => getBuilderCareerEntries(), {
    initialValue: [],
  });
  const props = mergeProps(
    {
      sectionTitle: "Section Title",
      bodyContent:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Excepturi magni facere atque unde molestias repellat, quaerat, at pariatur laudantium dolorum, minima quasi iure tempora sunt dolores omnis sed aliquam est!",
    },
    inProps
  );
  return (
    <Show when={content()}>
      <section
        id={props.sectionId}
        class="py-10 flex flex-col items-center"
        classList={{ "boundary-outer": !props.fullWidth }}
      >
        <div
          class="flex flex-col gap-5 justify-center items-center pb-10"
          classList={{
            [contentWidth[props.contentWidth]]: !!props.contentWidth,
          }}
        >
          <h2 class="text-3xl font-bold">{props.sectionTitle}</h2>
          <p class="text-center">{props.bodyContent}</p>
        </div>
        <div class="w-full">
          <div class="hidden md:grid md:grid-cols-careers-sm lg:grid-cols-careers gap-5 text-lg font-bold py-5 border-b-2">
            <h3>Open Positions</h3>
            <h3>Department</h3>
            <h3>Location</h3>
            <h3 class="text-center">Action</h3>
          </div>
          <div class="divide-y-2">
            <Show
              when={content()?.length > 0}
              fallback={
                <div class="my-6 py-8 px-4  max-w-xl mx-auto bg-roma-grey rounded-md text-center">
                  No available positions at this time. Please check back again
                  soon!
                </div>
              }
            >
              <For each={content()}>
                {(item) => (
                  <div class="grid  md:grid-cols-careers-sm lg:grid-cols-careers py-5 items-center gap-2 md:gap-5 border-t-2 md:border-t-0 relative">
                    <p>
                      <span class="font-bold mr-2 md:hidden">Position:</span>
                      <A
                        href={`/careers/${item.data?.slug}`}
                        class="text-roma-blue"
                      >
                        {item.name}
                      </A>
                    </p>
                    <p>
                      <span class="font-bold mr-2 md:hidden">Department:</span>
                      {item.data?.department}
                    </p>
                    <p>
                      <span class="font-bold mr-2 md:hidden">Location:</span>
                      {item.data?.location}
                    </p>
                    <div class="flex items-center gap-1 text-sm sm:justify-end sm:flex-col sm:absolute sm:right-0 sm:items-stretch md:flex-row md:static ">
                      <Button
                        generic={item?.data?.applyNowUrl.includes('mailto')}
                        style="solid"
                        // href="https://docs.google.com/forms/d/e/1FAIpQLSdOhNnjBjRrGUmqYUEr_1WvbJnhHDDCapV2kqFKLlrx5pa7lA/viewform?vc=0&c=0&w=1&flr=0"
                        href={item.data?.applyNowUrl}
                        target="_blank"
                      >
                        <span class="px-1">Apply</span>
                      </Button>
                      <Button
                        style="outline"
                        href={`mailto:?subject=Roma Moulding is Hiring! ${item.name}&body=Check out the job listing here! https://www.romamoulding.com/jobs/${item.data?.slug}`}
                        generic={true}
                      >
                        <div class="flex items-center px-1">
                          <div>Share</div>
                          <Icon class="w-4 ml-2" path={link} />
                        </div>
                      </Button>
                    </div>
                  </div>
                )}
              </For>
            </Show>
          </div>
        </div>
      </section>
    </Show>
  );
};

export default OpenPositions;
